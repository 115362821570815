import React from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { isAndroid } from "react-device-detect";

import { Button } from "./index";
import buttonBg from "../../assets/images/button_bg.png";

const PLAYSTORE = "https://play.google.com/store/apps/details?id=com.sati";
const APPSTORE =
  "https://apps.apple.com/ru/app/sati-%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D0%BE%D1%81%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%BD%D0%BE%D0%B9-%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8/id1513177968";

export default withTranslation()(({ t, ...rest }) => (
  <ButtonStyled as={"a"} href={isAndroid ? PLAYSTORE : APPSTORE} {...rest}>
    {t("BlockButton")}
  </ButtonStyled>
));

const ButtonStyled = styled(Button)`
  position: relative;
  z-index: 1;
  min-width: 220px;
  font-family: SF Pro, Arial, sans-serif;
  font-size: 19px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ffffff;
  box-shadow: 0px 35px 50px rgba(35, 49, 64, 0.25);
  border: unset;
  border-radius: 12px;
  margin-top: 18px;
  padding: 0.3em 3.99em;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url(${buttonBg}) center center repeat;
    background-size: cover;
    z-index: -1;
    border-radius: 12px;
  }
  ${(p) => p.theme.max("xs")`
    width: 100%;
  `}
`;
